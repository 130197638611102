<template>
  <section class="hero">
    <div class="container-lg">
      <div class="row align-items-lg-center">
        <div class="col-lg-5 offset-lg-1">
          <div class="header-image">
            <img
              class="img-fluid"
              src="../assets/header-photo.png"
              alt="Bobline kapsel"
            />
          </div>
        </div>
        <div class="col-lg-auto ms-auto">
          <div class="header-text gap-4 d-flex flex-wrap">
            <a class="btn btn--header btn-secondary" href="tel:0681971073"
              ><font-awesome-icon icon="fa-solid fa-phone" /><span
                >Direct bellen</span
              ></a
            >
            <a
              class="btn btn--header btn-secondary btn--outline"
              href="https://wa.me/31681971073"
              ><font-awesome-icon icon="fa-brands fa-whatsapp" />
              <span>Afspraak maken</span></a
            >
          </div>
        </div>
      </div>

      <h1 class="logo">
        <div>
          <span>S</span>
          <span class="text__outlined">A</span>
          <span class="text__outlined">M</span>
          <span class="text__outlined">A</span>
          <span>N</span>
          <span>T</span>
          <span>H</span>
          <span>A</span>
          <span>S</span>
        </div>
        <div>
          <span>H</span>
          <span class="text__outlined">A</span>
          <span class="text__outlined">I</span>
          <span class="text__outlined">R</span>
          <span>H</span>
          <span>O</span>
          <span>U</span>
          <span>S</span>
          <span>E</span>
        </div>
      </h1>
    </div>
  </section>
</template>

<script>
export default {
  name: "MainNav",
  data() {
    return {
      company: "Bobo careers",
    };
  },
};
</script>
