<template>
  <div class="section" id="contact">
    <div class="container">
      <div class="row align-items-center gy-4">
        <div class="col-md-6">
          <img
            class="img-fluid"
            v-bind:src="image.url"
            alt="{{ image.title }}"
            data-aos="fade-up"
          />
        </div>
        <div class="col-md-5 ms-auto" data-aos="fade-up">
          <h4 class="fs-1 title">
            <span>C</span>
            <span>o</span>
            <span>n</span>
            <span>t</span>
            <span class="text__outlined">a</span>
            <span class="text__outlined">c</span>
            <span class="text__outlined">t</span>
          </h4>

          <p>Benieuwd naar de mogelijkheden?</p>
          <p>Bel mij vrijblijvend om samen de mogelijkheden te bespreken.</p>

          <p class="mt-4 pt-4"><b>Adres:</b></p>
          <p>
            Deldensestraat 1A <br />
            7601RZ Almelo
          </p>

          <a class="d-block pt-2" href="https://wa.me/31681971073">
            <font-awesome-icon icon="fa-solid fa-phone" class="pe-2" />06
            81971073
          </a>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "ContactBlock",
  data() {
    return {
      image: {
        url: require("@/assets/kapsalon-3.jpg"),
        title: "Samantha aan het werk in de kapsalon",
      },
    };
  },
};
</script>
