<template>
  <section class="treatments" id="behandelingen">
    <div class="container">
      <div class="row">
        <div class="col-auto mx-auto ms-lg-auto">
          <h2 class="title title--small">
            <span>B</span>
            <span>e</span>
            <span>h</span>
            <span>a</span>
            <span>n</span>
            <span>d</span>
            <span>e</span>
            <span class="text__outlined text__muted">l</span>
            <span class="text__outlined text__muted">i</span>
            <span class="text__outlined text__muted">n</span>
            <span class="text__outlined text__muted">g</span>
            <span class="text__outlined text__muted">e</span>
            <span class="text__outlined text__muted">n</span>
          </h2>
        </div>
      </div>

      <div class="row">
        <div class="col-12">
          <ul
            class="list-treatments"
            data-aos="fade-up"
            v-for="(treatment, index) in treatments"
            v-bind:key="index"
          >
            <li class="list-treatments__item list-treatments__item--main">
              <span>{{ treatment.treatment }}</span>
              <ul class="list-treatments list-treatments--second">
                <li
                  class="list-treatments__item"
                  v-for="(treatment, index) in treatment.list"
                  v-bind:key="index"
                >
                  <span>{{ treatment.treatment }}</span>
                  <span class="list-treatments__price">{{
                    treatment.price
                  }}</span>
                </li>
              </ul>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
  name: "TreatmentsBlock",
  data() {
    return {
      treatments: [
        {
          treatment: "Knippen",
          list: [
            {
              treatment: "Dames – Wassen, knippen en drogen",
              price: "€ 18,-",
            },
            {
              treatment: "Heren - wassen, knippen en stylen. ",
              price: " € 18,-",
            },
            {
              treatment: "Dames - wassen en model föhnen",
              price: "€ 20,-",
            },
          ],
        },
        {
          treatment: "Kleuren",
          list: [
            {
              treatment: "Uitgroei bijwerken",
              price: "€ 33,50 ",
            },
            {
              treatment: "Totaal kleuring:",
              price: "€ 50,-",
            },
            {
              treatment: "Toner",
              price: "€ 35,-",
            },
            {
              treatment: "Langhaar toeslag",
              price: "€ 10,-",
            },
          ],
        },
        {
          treatment: "Highlights / babylights",
          list: [
            {
              treatment: "Fullhead folies (inc. toner)",
              price: "Vanaf 145,-",
            },
            {
              treatment: "Half Head folies",
              price: "Vanaf 91,50",
            },
            {
              treatment: "Folies 25 stuks",
              price: "€ 50,-",
            },
            {
              treatment: "Folies 12 stuks",
              price: "€ 38,50",
            },
          ],
        },
        {
          treatment: "Bayalage",
          list: [
            {
              treatment: "Bayalage",
              price: "Vanaf 120,-",
            },
          ],
        },
        {
          treatment: "Extra behandelingen ",
          list: [
            {
              treatment: "K18 behandeling helemaal",
              price: "€ 29,95",
            },
            {
              treatment: "K18 behandeling wasbehandeling",
              price: "€ 21,95",
            },
          ],
        },
      ],
    };
  },
  methods: {
    computedClass(index) {
      return index % 2 ? "test" : "";
    },
  },
};
</script>
